import React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Articles() {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        (async function loadPosts() {
            const data = (await axios.get(`${process.env.REACT_APP_BLOG_URL}`, {
            })).data.slice(0, 3);
            setPosts(data);
        })();
    }, []);
    if (posts.length < 1) {
        return null;
    }
    else {
        return (
            <section className="relative overflow-hidden box">
                <div className="mb-6 text-center">
                    <span className="text-2xl content font-medium">Nos derniers articles idées cadeau :</span>
                </div>
                <div className="container mx-auto ">
                    <div className="sm-6 flex flex-wrap">
                        {posts.map((post) => (
                            <div className="pb-6 relative w-full px-6 lg:w-1/3" key={post.id}>
                                <div className="relative shadow-xl z-10 rounded-large bg-white w-2/3 mx-auto">
                                    <div className="relative mb-3 h-56">
                                        <a href={post.link}>
                                            <img className="h-full w-full rounded-t-large object-cover" src={post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url} alt={"Image de l'article " + post.title.rendered} />
                                        </a>
                                    </div>
                                    <div className="px-6 font-semibold text-xl" dangerouslySetInnerHTML={{ __html: post.title.rendered }}></div>
                                    <div className="px-6 pt-2 text-2 font-medium text-blogDesc h-32 overflow-hidden" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered.substring(0, 166) + ""+" ..." }}></div>
                                    <div className="px-6 pt-2 text-2 text-lirePlus"><a href={post.link}>lire plus</a></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center">
                        <button className="mt-3 lg:text-xl bg-safran text-xl font-medium rounded-full px-4 py-2">
                            <FontAwesomeIcon icon="fa-solid fa-circle-chevron-right" className='pr-3' />
                            <a href={process.env.REACT_APP_BLOG}>Voir plus d'articles</a>
                        </button>
                    </div>
                </div>
            </section>
        )
    }
}