import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavBar from '../NavBar';
import Footer from '../Footer';
import Blob from '../blob';
import Adsense from '../Adsense';
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import '../../notes.css'

var questionsHistory = [];

function App(props) {
  var handleSliderChange = function (event, newValue) {
    setAge(newValue);
  };
  const [question, setQuestion] = useState('Pour commencer, pour qui cherches tu un cadeau ?');
  const [age, setAge] = useState(1);
  const [gender, setGender] = useState('Homme');
  const [idQuestions, setidQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [reponses, setReponses] = useState([]);
  const [type, setType] = useState('');
  const [typeAfficher, setTypeAfficher] = useState('');
  const [answers, setAnswers] = useState([]);
  const [idAnswers, setidAnswers] = useState([]);
  const [isSuperQuestion, setIsSuperQuestion] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoading(true);

    questionsHistory.push({ type: type, question: question, answers: answers });
    const data = {
      age: age,
      gender: gender,
      questionsReponses: questionsHistory,
      idAnswers: idAnswers,
      idQuestions: idQuestions,
      isSuperQuestion: isSuperQuestion,
    }

    axios.post(`${process.env.REACT_APP_API_URL}sendrequest`, data, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
      }
    }).then(res => {
      if (res.data.token) {
        navigate('/results?token=' + res.data.token);
      }
      else {
        setQuestion(res.data.newQuestion.description);
        if(res.data.newQuestion.libelle === "QCM")
          setTypeAfficher("Question à choix multiples");
        if(res.data.newQuestion.libelle === "STANDARD")
          setTypeAfficher('Question à choix unique')
        setType(res.data.newQuestion.libelle);
        setQuestions([...questions, res.data.newQuestion]);
        setReponses([res.data.answers]);
        setIsSuperQuestion([...isSuperQuestion, res.data.newQuestion.isSuperQuestion]);
        setAnswers([]);
        setidQuestions([...idQuestions, res.data.newQuestion.idQuestion]);
        setLoading(false);
      }

    }).catch(err => {
      setLoading(false);
    });
  }

  return (
    <div className='md:px-12 content flex flex-col min-h-screen justify-between '>
      <div className='flex-grow '>

        <NavBar />
        <Blob />

        <div className='mt-3 '>
          <div className='flex justify-center '>
            <div className="flex items-center md:justify-start shadow-xl rounded-xl my-3 bg-white w-full">
              <div className='flex items-center h-[100%] text-4xl rounded-l-xl bg-safran'>
                <FontAwesomeIcon icon="fa-regular fa-circle-question" className='bg-white rounded-full text-safran text-6xl mx-6' />
              </div>
              <div className="flex-col justify-center mx-auto">
                <div className="bg-white md:text-xl lg:text-3xl leading-12 font-medium rounded-xl p-6 mx-auto">
                  {question}
                </div>
                <div className="italic text-safran md:text-xl leading-12 font-medium rounded-xl p-6 w-96 mx-auto">
                  {typeAfficher}
                </div>
              </div>

            </div>
          </div>

        </div>

        <div>
          {(() => {
            switch (type) {
              // QCM
              case "QCM": return <div className='md:px-12 content flex flex-col justify-between '>
                <div className='flex-grow '>
                  <form>
                    <div className='flex mt-12 justify-center'>
                      <div className="pb-12">
                        <div className=''>
                          <div className='flex rounded-large w-auto h-auto'>
                            {Object.keys(reponses).map((key) => {
                              return (
                                <div key={key} className="flex flex-wrap xl:mx-48">
                                  {reponses[key].map((dataItem) => {
                                    return (
                                      <div key={dataItem.id} id="questions" className='p-4 flex flex-grow'>

                                        <input onChange={(e) => [setidAnswers([...idAnswers, dataItem.idAnswer]),setAnswers([...answers, [e.target.value]])]} className="hidden" name="rGroup" type="checkbox" value={dataItem.description} id={'r' + dataItem.id} />
                                        <label className="text-sm md:text-xl lg:text-3xl shadow-xl" htmlFor={'r' + dataItem.id}>{dataItem.description}</label>
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>;

              // ChoixUnique
              case "STANDARD": return <div className='md:px-12 content flex flex-col justify-between '>
                <div className='flex-grow'>
                  <form>
                    <div className='flex mt-12 justify-center'>
                      <div className="pb-12">
                        <div className=''>
                          <div className='flex rounded-large w-auto h-auto'>
                            {Object.keys(reponses).map((key) => {
                              return (
                                <div key={key} className="flex flex-wrap xl:mx-48">
                                  {reponses[key].map((dataItem) => {
                                    return (
                                      <div key={dataItem.id} id="questions" className='p-4 flex flex-grow'>
                                        <input onChange={(e) => [setidAnswers([...idAnswers, dataItem.idAnswer]), setAnswers([e.target.value])]} className="hidden" name="rGroup" type="radio" value={dataItem.description} id={'r' + dataItem.id} />
                                        <label className="text-sm md:text-xl lg:text-3xl shadow-xl" htmlFor={'r' + dataItem.id}>{dataItem.description}</label>
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>;

              case "NOTE": return <div className='md:px-12 content flex flex-col justify-between '>
                <div className='flex-grow '>
                  <form>
                    <div className='flex mt-12 justify-center'>
                      <div className="pb-12">
                        <div className=''>
                          <div className='flex rounded-large w-auto h-auto'>
                            {Object.keys(reponses).map((key) => {
                              return (
                                <div key={key} className="flex flex-wrap xl:mx-48">
                                  {reponses[key].map((dataItem) => {
                                    return (
                                      <div key={dataItem.id} id="questions" className='p-4 flex flex-grow'>
                                        <input onChange={(e) => [setidAnswers([...idAnswers, dataItem.idAnswer]), setAnswers([...answers, [e.target.value]])]} className="hidden" name="rGroup" type="checkbox" value={dataItem.description} id={'r' + dataItem.id} />
                                        <label className="text-sm md:text-xl lg:text-3xl shadow-xl" htmlFor={'r' + dataItem.id}>{dataItem.description}</label>
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>;

              // Notes
              // case "NOTE": return <div className='md:px-12 content flex flex-col justify-between notes'>
              //   <div className='flex-grow'>
              //     {Object.keys(reponses).map((key) => {
              //       return (
              //         <div key={key} className="xl:mx-48">
              //           {reponses[key].map((dataItem) => {
              //             return (
              //               <form>
              //                 <div className='flex mt-8 justify-center'>
              //                   <div className="pb-12">
              //                     <div className=''>
              //                       <div className='flex rounded-large w-auto h-auto'>
              //                         <div className="container">
              //                           <div className="feedback">
              //                             <div className="rating shadow-xl rounded-xl bg-emoji">
              //                               <input className="r-5" type="radio" name="rating" id={dataItem.id} />
              //                               <label htmlFor={dataItem.id}><FontAwesomeIcon className="bg-white rounded-full p-1" icon="fas fa-angry" /></label>
              //                               <input className="r-4" type="radio" name="rating" id={dataItem.id} />
              //                               <label htmlFor={dataItem.id}><FontAwesomeIcon className="bg-white rounded-full p-1" icon="fas fa-frown" /></label>
              //                               <input className="r-3" type="radio" name="rating" id={dataItem.id} />
              //                               <label htmlFor={dataItem.id}><FontAwesomeIcon className="bg-white rounded-full p-1" icon="fas fa-face-meh" /></label>
              //                               <input className="r-2" type="radio" name="rating" id={dataItem.id} />
              //                               <label htmlFor={dataItem.id}><FontAwesomeIcon className="bg-white rounded-full p-1" icon="fas fa-smile" /></label>
              //                               <input className="r-1" type="radio" name="rating" id={dataItem.id} />
              //                               <label htmlFor={dataItem.id}><FontAwesomeIcon className="bg-white rounded-full p-1" icon="fas fa-grin-beam" /></label>
              //                               <div className="flex h-auto bg-white rounded-r-large">
              //                                 <div className="md:text-xl lg:text-3xl sm:leading-9 leading-6 center font-medium m-auto px-3 ">
              //                                   <h3>Tennis</h3>
              //                                 </div>
              //                               </div>
              //                               <div className="emoji-wrapper">

              //                                 <div className="emoji bg-white pl-9">
              //                                   <FontAwesomeIcon icon="fa-regular fa-face-meh" />
              //                                   <FontAwesomeIcon icon="fa-regular fa-grin-beam" />
              //                                   <FontAwesomeIcon icon="fa-regular fa-smile" />
              //                                   <FontAwesomeIcon icon="fa-regular fa-face-meh" />
              //                                   <FontAwesomeIcon icon="fa-regular fa-frown" />
              //                                   <FontAwesomeIcon icon="fa-regular fa-angry" />
              //                                 </div>
              //                               </div>
              //                             </div>
              //                           </div>
              //                         </div>
              //                       </div>
              //                     </div>
              //                   </div>
              //                 </div>
              //               </form>
              //             )
              //           })}
              //         </div>
              //       )
              //     })}
              //   </div>
              // </div>;

              default: return <div className='lg:flex mt-24 justify-center mb-4'>
                <div className="text-center maleFemale flex justify-center whitespace-nowrap">
                  <div className='w-[260px]'>
                    <p className='flex justify-center'>{gender}</p>
                    <label>
                      <input className="hidden" type="radio" name="radio" value="Homme" defaultChecked onChange={() => { setGender('Homme') }} />
                      <div className="box rounded-l-large w-[130px] h-[200px]">
                        <span className=''><FontAwesomeIcon icon="fas fa-male" className='h-[120px]' /></span>
                      </div>
                    </label>
                    <label>
                      <input className="hidden" type="radio" name="radio" value="Femme" onChange={() => { setGender('Femme') }} />
                      <div className="box rounded-r-large w-[130px] h-[200px]">
                        <span><FontAwesomeIcon icon="fas fa-female" className='h-[120px]' /></span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className=" lg:ml-24">
                  <div className='flex-row'>
                    <p className='flex justify-center'>De {age} ans</p>
                    <div className='flex items-center shadow-xl rounded-large bg-white h-[200px] px-12'>
                      <span className='mr-5'>1</span>
                      <Slider
                        value={typeof age === "number" ? age : 1}
                        defaultValue={1}
                        onChange={handleSliderChange}
                        min={1}
                        max={99}
                        sx={{
                          width: 800,
                          color: 'blue-500',
                          height: 20,
                          '& .MuiSlider-thumb': {
                            width: 30,
                            height: 30,
                            color: "#facc57",
                          },
                        }}
                      />
                      <span className='ml-5'>99</span>
                    </div>
                  </div>
                </div>
              </div>;
            }
          })()}
        </div>
        <div className="mb-6 text-center mb-12">
          <button
            type="submit" className="mt-3 lg:text-xl bg-safran text-xl font-medium rounded-full px-12 py-2" onClick={handleSubmit} disabled={loading}>
            <FontAwesomeIcon icon="fa-solid fa-circle-chevron-right" className='pr-3' />{loading ? 'Chargement...' : 'Suivant'}
          </button>
        </div>
      </div>
      <Adsense />
      <Footer />
    </div>
  );
}

export default App;