import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cadeau from '../../images/cadeau.png';
import Articles from '../Articles';
import NavBar from '../NavBar';
import Footer from '../Footer';
//import Adsense from '../Adsense';
import Blob from '../blob';

export default function Tags(props) {
  return (
    <div className='md:px-12 content flex flex-col min-h-screen justify-between'>
      <div>

        <NavBar />
        <Blob />

        <div className="md:flex md:my-6">

          <div className="md:w-2/3 content w-full my-5 ml-5 md:ml-0">
            <div className="xl:text-6xl xl:leading-[90px] text-4.5xl font-medium md:mx-20 lg:mx-auto">
              <span className="highlight">Trouve</span> facilement <br />un <span className="highlight">cadeau pour</span>
              <div className="overflow-hidden h-11 xl:h-[80px] center whitespace-nowrap">

                <ul className="content__container__list">
                  <li className="content__container__list__item">un ami.</li>
                  <li className="content__container__list__item">ton père.</li>
                  <li className="content__container__list__item">ta mère.</li>
                  <li className="content__container__list__item">ton petit neveu.</li>
                  <li className="content__container__list__item">ton fils.</li>
                  <li className="content__container__list__item">ta fille.</li>
                  <li className="content__container__list__item">ton conjoint.</li>
                  <li className="content__container__list__item">ta mamie.</li>
                  <li className="content__container__list__item">ton papi.</li>
                  <li className="content__container__list__item">et tant d'autres !</li>
                </ul>
              </div>
              <div className="flex md:justify-start justify-center">
                <button className="xl:text-3xl lg:leading-[60px] mt-3 lg:text-xl bg-safran text-xl w-3/4 font-bold rounded-full">
                  <FontAwesomeIcon icon="fa-solid fa-gift" className="xl:text-3xl text-2xl px-2" />
                  <a href="/questions">TROUVE TON CADEAU</a>
                </button>
              </div>
            </div>
          </div>

          <div className="box md:w-1/2 flex flex-col items-end">
            <div className="rounded-large shadow-xl px-9 py-5 md:h-auto m-5 bg-white">
              <p className='text-cblue font-bold'>Pas d'idée de cadeau ? <FontAwesomeIcon icon="fa-regular fa-lightbulb" /></p>
              <div className="flex">
                <p className="mt-12 font-semibold text-standart">CadeauPour est un <span className='text-cblue'>générateur de cadeau</span> qui avec quelques questions t'aideras à trouver un ou des cadeaux.</p>
                <img src={cadeau} className="h-48 w-48 hidden xl:block" alt="cadeau" />
              </div>
              <br />
              <p className="text-safran">
                <a href="/Apropos">EN SAVOIR PLUS</a>
              </p>
            </div>
          </div>
        </div>

      </div>
      <Articles />
      <Footer />
    </div>
  )
}