import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from '../NavBar';
import Footer from '../Footer';
import Blob from '../blob';
import Adsense from '../Adsense';

export default function Results() {
    const [results, setResults] = useState([]);

    useEffect(() => {
        (async function () {
            const data = (await axios.get(`${process.env.REACT_APP_API_URL}aleatoire`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
                }
            })).data;
            setResults(data);
            console.log(data);
        })();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="xl:px-12 content">
            <NavBar />
            <Blob />
            <div className="grid xl:grid-cols-3 gap-3 md:grid-cols-2 justify-items-center">
                {results.map(a => <Resultats key={a.id} {...a}
                />)}
            </div>
            <Adsense />
            <Footer />
        </div>
    );
}

function Resultats(props) {
    return (
        <div className="rounded-xl overflow-hidden shadow-xl bg-white flex flex-col border max-w-sm mt-3 mb-12">
            <img className="rounded-t-lg mx-auto py-3" src={props.image_id} alt="cadeau" />
            <div className="px-6 py-4">
                <a href={props.url}>
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{props.title}</h5>
                </a>
                <p className="font-normal text-gray-700 dark:text-gray-400">{props.description}</p>
            </div>
            <div className="px-6 pt-4 pb-2 text-center mt-auto">
                <a href={props.url} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Voir plus
                    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </a>
            </div>
        </div>
    )
}