import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../images/logo.png';
import rectangle from '../images/rectangle.png';

export default function Tags() {
    return (
        <div>
            <footer className="pl-4 text-xl sticky bottom-0 mt-5 content">
                <div className=" ">
                    <div className="flex pl-6 font-medium leading-6">
                        <div>
                            <ul className="list-disc marker:text-safran">
                                <li className="">
                                    <a href="/Questions" className="hover:underline">Rechercher Un Cadeau</a>
                                </li>
                                <li>
                                    <a href="/Apropos" className="hover:underline">A Propos</a>
                                </li>
                                <li>
                                    <a href="/NousContacter" className="hover:underline">Nous Contacter</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="pl-12 list-disc marker:text-safran">
                                <li className="">
                                    <a href={process.env.REACT_APP_BLOG} className="hover:underline ">Blog Idées Cadeaux</a>
                                </li>
                                <li>
                                    <a href="/MentionsLegales" className="hover:underline">Mentions Légales</a>
                                </li>
                            </ul>
                        </div>
                        <div className="hidden sm:block absolute right-4 bottom-36">
                            <div className="flex flex-row items-end">
                                <img src={rectangle} className="h-4 w-16" alt="rec" />
                            </div>
                        </div>
                        <div className="hidden sm:block absolute sm:right-1 md:right-24 lg:right-80">
                            <a href="/">
                                <img src={logo} className="sm:h-16 h-20" alt="Logo" />
                            </a>
                        </div>
                    </div>

                </div>
                <div className="center pt-3 text-cblue font-medium">
                    <span><FontAwesomeIcon icon="fas fa-copyright" className='pr-2' /> Copyright - <a href="/" className="hover:underline">CadeauPour</a> 2022 </span>
                </div>
            </footer>
        </div>
    )
}