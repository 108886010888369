export default function Articles() {
    return (
        <div className="blob">
            <svg
                width="1000"
                height="1000"
                viewBox="0 0 1000 1000"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <linearGradient id="linearGradientId" gradientTransform="rotate(0 0.5 0.5)">
                        <stop offset="0%" stopColor="#F0F6FF" />
                        <stop offset="100%" stopColor="#ecf2fa" />
                    </linearGradient>

                    <clipPath id="shape">
                        <path fill="currentColor" d="M863,591.5Q752,683,675,741.5Q598,800,498.5,805Q399,810,269,786.5Q139,763,94.5,631.5Q50,500,74,354Q98,208,243.5,183.5Q389,159,521.5,93.5Q654,28,720,160Q786,292,880,396Q974,500,863,591.5Z"></path>
                    </clipPath>
                </defs>

                <g clipPath="url(#shape)">
                    <path fill="url(#linearGradientId)" d="M863,591.5Q752,683,675,741.5Q598,800,498.5,805Q399,810,269,786.5Q139,763,94.5,631.5Q50,500,74,354Q98,208,243.5,183.5Q389,159,521.5,93.5Q654,28,720,160Q786,292,880,396Q974,500,863,591.5Z" />
                </g>
            </svg>
        </div>
    )
}