import { Route, Routes } from "react-router-dom";
import './App.css';
import './intro.css';
import Home from './pages/Home/Home'
import Questions from './pages/questions/Questions'
import PageNotFound from './pages/PageNotFound'
import Apropos from './pages/divers/apropos'
import MentionLegales from './pages/divers/mentionLegales'
import Contacter from './pages/divers/contacter'
import Results from './pages/results/results'
import Chance from './pages/divers/jaiDeLaChance'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)

function App() {
  return (
          <Routes>
              <Route path='/' element={<Home/>} />
              <Route path='/Questions' element={<Questions/>} />
              <Route path='/Apropos' element={<Apropos/>} />
              <Route path='/MentionsLegales' element={<MentionLegales/>} />
              <Route path='/NousContacter' element={<Contacter/>} />
              <Route path='/aleatoire' element={<Chance/>} />
              <Route path="/*" element={<PageNotFound/>} />
              <Route path="/results" element={<Results/>}/>
          </Routes>
  );
}

export default App;