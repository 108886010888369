import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';

export default function apropos() {
    return (
        <div className='xl:px-12 content flex flex-col min-h-screen justify-between'>
            <NavBar/>
            <section className="md:w-1/2 mx-auto text-center">
                <h1 className="text-2xl pb-5">Qu'est-ce que CadeauPour ?</h1>
                <p>Au travers d'un questionnaire intelligent, nous vous aidons à trouver des cadeaux pour vos amis, les
                    membres de votre famille ou même pour vos simples connaissances.
                    Nous essayons, au travers de questions simples, de définir un profil et d'associer des cadeaux qui
                    feront plaisir et qui ne finiront pas dans un placard ou sur leboncoin dès le lendemain.</p>
                <h1 className="text-2xl py-5"> Qu'est-ce que le blog CadeauPour ?</h1>
                <p> Le blog permet également de proposer des idées de cadeau au travers de top par catégories, de
                    cadeaux
                    appréciés lors d'événement.</p>
                <h1 className="text-2xl py-5"> Est-ce que nous vendons des produits ?</h1>
                <p>Non et nous ne le ferons jamais.
                    Cela nous permet de rester neutre dans nos propositions de cadeaux.</p>
            </section>
            <Footer/>
        </div>
    )
}