import React from 'react';
import logo from '../images/logo.png';
import rectangle from '../images/rectangle.png';

export default function Tags(props) {
    function handleClick(){
        let menu = document.querySelector('#menu');
        menu.classList.toggle('hidden');
    }
    return (

        <header>
            <nav className="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 text-lg bg-white content">
                <div>
                    <a href="/">
                        <img src={logo} className="sm:h-32 h-16" alt="Logo" />
                    </a>
                </div>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="menu-button"
                    onClick={handleClick}
                    className="h-6 w-6 cursor-pointer md:hidden block"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path color="#facc57" d="M4 6h32M4 12h32M4 18h32"/>
                </svg>

                <div className="hidden w-full md:flex md:items-center md:w-auto" id="menu">
                    <ul className="pt-4 text-base content md:flex md:justify-between mx-auto md:pt-0 md:text-lg lg:text-2xl xl:text-3xl">
                        <li className="md:p-4 py-2 block hover:underline">
                            <a href="/questions">Trouve ton cadeau</a>
                        </li>
                        <li className="md:p-4 py-2 block hover:underline">
                            <a href="/aleatoire">J'ai de la chance</a>
                        </li>
                        <li className="md:p-4 py-2 block hover:underline">
                            <a href={process.env.REACT_APP_BLOG}>Blog</a>
                        </li>
                        <li className="md:p-4 py-2 block hover:underline">
                            <a href="/Apropos">A Propos</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="flex flex-row mt-6">
                <img src={rectangle} className="pl-1 h-4 w-16" alt="rec"/>
            </div>
        </header>
    )
}