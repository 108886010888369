import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';

export default function mentionLegales() {
    return (
        <div className='xl:px-12'>
            <NavBar/>
            <section className='justify-center content mb-20'>
                <p><span className="font-bold">Editeur du Site :</span> SARL UBNI</p>
                <p>Numéro de SIRET : 84114323300010</p>
                <p>Email : contact@ubni.fr</p>
                <p>Site Web : www.ubni.fr</p>

                <p className="font-bold pt-5">Hébergement :</p>
                <p>Hébergeur : O2SWITCH</p>
                <p>Site Web : https://www.o2switch.fr/</p>
                <p className="font-bold pt-5">Développement :</p>
                <p>SARL UBNI</p>
                <p>Site Web : www.ubni.fr</p>
                <p className="font-bold pt-5">Conditions d’utilisation :</p>
                <p>Ce site (www.cadeaupour.fr) est proposé en différents langages web
                (HTML5, Javascript, CSS, etc…) pour un meilleur confort d’utilisation et
                un graphisme plus agréable.
                Nous vous recommandons de recourir à des navigateurs modernes comme
                Edge, Safari, Firefox, Google Chrome, etc….
                La société UBNI met en œuvre tous les moyens dont elle dispose,
                    pour assurer une information fiable et une mise à jour fiable de
                    ses sites internet. Toutefois, des erreurs ou omissions peuvent
                    survenir et signaler toutes modifications du site qu’il jugerait
                    utile. UBNI n’est en aucun cas responsable de l’utilisation
                    faite de ces informations, et de tout préjudice direct ou
                    indirect pouvant en découler.</p>
                <p className="font-bold pt-5">Cookies :</p>
                <p>Le site www.cadeaupour.fr peut-être amené à vous demander
                    l’acceptation des cookies pour des besoins de
                    statistiques et d’affichage. Un cookie est une
                    information déposée sur votre disque dur par le serveur
                    du site que vous visitez. Il contient plusieurs données
                    qui sont stockées sur votre ordinateur dans un simple
                    fichier texte auquel un serveur accède pour lire et
                    enregistrer des informations . Certaines parties de ce
                    site ne peuvent être fonctionnelles sans l’acceptation
                    de cookies.</p>
                <p className="font-bold pt-5">Services fournis et CGV :</p>
                <p>CadeauPour est un générateur d'idée de cadeaux.
                    Aucun des produits présents sur le site n'est
                    vendu par CadeauPour. Les produits sont vendus
                    par nos partenaires et nous ne sommes donc pas
                    responsable de la vente de ceux-ci.</p>
            </section>
            <Footer/>
        </div>
    )
}