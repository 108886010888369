import React, { Component } from "react";

class Adsense extends Component {
    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <ins
                className="adsbygoogle"
                data-ad-client="ca-pub-7714688513560306"
                data-ad-slot=""
                style={{ display: "block", height: 250 }}
            />
        );
    }
}

export default Adsense