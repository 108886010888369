import React, {useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NavBar from '../NavBar';
import Footer from '../Footer';
import Blob from '../blob';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReCAPTCHA from "react-google-recaptcha"

export default function App(props) {
    const [email, setemail] = useState("");
    const [nom, setnom] = useState("");
    const [sujet, setsujet] = useState("");
    const [contenu, setcontenu] = useState("");
    const captchaRef = React.createRef();

    const handleSubmit = (event, value) => {
        event.preventDefault();
        const response = captchaRef.current.getValue();
        const secret = `${process.env.REACT_APP_SECRET_KEY}`;

        const data = {
            email: email,
            nom: nom,
            sujet: sujet,
            contenu: contenu,
            response: response,
            secret: secret,
        }

        const MySwal = withReactContent(Swal)

        axios.post(`${process.env.REACT_APP_API_URL}send/email`, data, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
            }
        })
            .then(res => {
                if (res.data.verify.success === true) {
                    MySwal.fire({
                        title: res.data.message,
                        html: <i>Nous vous répondrons dans les meilleurs délais.</i>,
                        icon: 'success'
                    })
                    event.target.reset();
                    captchaRef.current.reset();
                } else {
                    MySwal.fire({
                        title: res.data.message,
                        html: <i>Si le captcha ne s'affiche pas correctement, veuillez recharger la page.</i>,
                        icon: 'error'
                    })
                }
            })
    }

    return (
        <div className='md:px-12 content min-h-screen'>
            <NavBar/>
            <Blob/>
            <div className="flex justify-center">


                {/*form*/}
                <form className="w-full max-w-lg" onSubmit={handleSubmit} id="contact-form">
                    <h1 className="text-3xl mb-5 flex justify-center">Formulaire de contact</h1>
                    {/*champ nom*/}
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3">
                            <label className="content block text-md mb-2"
                                   htmlFor="grid-password">
                                Nom complet
                            </label>
                            <input required
                                className="appearance-none block w-full shadow-xl text-gray-700 border border-gray-200 rounded px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="nom" type="text" onChange={event => setnom(event.target.value)}/>
                            <p className="text-gray-600 text-xs italic">Veuillez renseigner votre nom ainsi que votre
                                prénom.</p>
                        </div>
                    </div>
                    {/*champ email*/}
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3">
                            <label className="text-md mb-2"
                                   htmlFor="grid-password">
                                E-mail
                            </label>
                            <input required
                                className="appearance-none block w-full shadow-xl text-gray-700 border border-gray-200 rounded px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="email" type="email" onChange={event => setemail(event.target.value)}/>
                            <p className="text-gray-600 text-xs italic">Veuillez renseigner une adresse mail permettant
                                de vous contacter.</p>
                        </div>
                    </div>
                    {/*champ sujet*/}
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3">
                            <label className="text-md mb-2"
                                   htmlFor="grid-password">
                                Sujet de la demande
                            </label>
                            <input required
                                className="appearance-none block w-full shadow-xl text-gray-700 border border-gray-200 rounded px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="sujet" type="text" onChange={event => setsujet(event.target.value)}/>
                            <p className="text-gray-600 text-xs italic">Veuillez renseigner le sujet de votre
                                demande.</p>
                        </div>
                    </div>
                    {/*champ contenu*/}
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="text-md mb-2"
                                   htmlFor="grid-password">
                                Message
                            </label>
                            <textarea required
                                className="no-resize appearance-none block w-full shadow-xl text-gray-700 border border-gray-200 rounded px-4 mb-1 leading-tight focus:outline-none focus:border-gray-500 h-40 resize-none"
                                id="message" onChange={event => setcontenu(event.target.value)}></textarea>
                            <p className="text-gray-600 text-xs italic">Veuillez formuler votre demande avec le plus de
                                précisions possibles.</p>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <div className="">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={captchaRef}
                            />
                            <button
                                type="submit"
                                className="shadow-xl my-3 lg:text-xl bg-safran text-xl font-medium rounded-full px-12 py-2">
                                <FontAwesomeIcon icon="fa-solid fa-circle-chevron-right" className='pr-3'/>Envoyer
                            </button>
                        </div>
                    </div>

                </form>
            </div>
            {/*<Adsense/>*/}
            <Footer/>
        </div>
    );
}